<template>
	<div class=".gg-container-1">
	  <div class="search-container">
		<div class="search-container-fn-input">
		  <label>机构编码：</label>
		  <el-input
			style="width: 200px"
			size="small"
			placeholder="机构编号或机构名称"
			prefix-icon="el-icon-search"
			clearable
			@change="reset"
			v-model="searchParams.org_name"
		  >
		  </el-input>
		</div>
			<el-button
				size="mini"
				type="primary"
				style="padding: 6px 7px;margin-left: 5px;
                              background-color: #2362FB; border-color: #2362FB"
				@click="reset"
			>
				<span>查找</span>
			</el-button>
		<div
		  style="margin-left: auto; display: flex; justify-content: space-around"
		>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('upload') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-refresh"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
									  background-color: #2362FB; border-color: #2362FB"
			  @click="institutionUpload(multipleSelectionPres[0].sign_id)"
			  v-if="multipleSelectionPres.length == 1"
			  >上传
			</el-button>
		  </div>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('update_table') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-edit-outline"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
			  @click="handleEditTableHead"
			  >编辑表头
			</el-button>
		  </div>
		  <div class="search-container-fn-input" v-if="operateList.indexOf('reset_query') > -1">
			<el-button
			  size="mini"
			  type="primary"
			  icon="el-icon-refresh"
			  style="margin-right: 10px; padding: 6px 7px;margin-left: 5px;
										background-color: #2362FB; border-color: #2362FB"
			  @click="handleResetSearch"
			  >重置查找
			</el-button>
		  </div>
		  <!--对应第三方图标的button样式-->
		</div>
	  </div>
	  <!----------------表格---------------->
	  <el-table
		border
		size="mini"
		v-loading="listLoading"
		:header-cell-style="{ 'text-align': 'center' }"
		:data="tableData"
		height="725"
		@selection-change="handleSelectionChange"
		style="width: 100%;z-index:0"
	  >
		<el-table-column type="selection" width="55" align="center" />
		<el-table-column type="index" align="center" width="50">
		</el-table-column>
		<template v-for="(column, index) in tableHead">
		  <el-table-column
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-if="column.field_type === 'textBtn' && column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <a
				style="font-size: 12px; color: #2379fb"
				@click.prevent="
				  currUser = scope.row;
				  getInfo(scope.row.doctors_id);
				"
			  >
				{{ scope.row[column.column_prop] }}
			  </a>
			</template>
		  </el-table-column>
		  <!-- <el-table-column
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-else-if="column.field_type === 'image' && column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <el-avatar :src="scope.row.head_pic"></el-avatar>
			</template>
		  </el-table-column> -->
		  <el-table-column
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-else-if="column.field_type === 'select' && column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <span>{{
				scope.row[column.column_prop] == 1 ? "启用" : "禁用"
			  }}</span>
			</template>
		  </el-table-column>
		  <el-table-column
			:sortable="tableHeadSortable.includes(column.column_prop)"
			:prop="column.column_prop"
			:label="
			  column.column_label_user_definition
				? column.column_label_user_definition
				: column.column_label
			"
			:width="column.width ? column.width : ''"
			:key="index"
			align="center"
			v-else-if="column.visible === true"
			show-overflow-tooltip
		  >
			<template slot-scope="scope">
			  <a v-if="column.column_prop == 'supervise_status'">{{
				getSuperviseName(scope.row.supervise_status)
			  }}</a>
			  <a v-else-if="column.column_prop == 'examine_status'">{{
				getExamineName(scope.row.examine_status)
			  }}</a>
			  <a v-else-if="column.column_prop == 'sign_life'"
				>{{ scope.row.sign_life }}年</a
			  >
			  <a v-else>{{ scope.row[column.column_prop] }}</a>
			</template>
			<template #header>
			  <el-popover
				placement="bottom"
				title=""
				min-width="160"
				trigger="click"
				v-if="
				  searchTableHead.filter(
					(item) => item.name == column.column_prop
				  ).length > 0
				"
			  >
				<span slot="reference" class="search-header">
				  <span class="search-title">{{
					column.column_label_user_definition
					  ? column.column_label_user_definition
					  : column.column_label
				  }}</span>
				  <i
					style="margin-left: 3px;font-weight: bolder; font-size: 20px"
					class="el-icon-search"
					:class="{
					  isSearch: searchTableHead.filter(
						(item) => item.name == column.column_prop
					  )[0].isSearch,
					  'el-icon-zoom-in': searchTableHead.filter(
						(item) => item.name == column.column_prop
					  )[0].isSearch,
					}"
				  />
				</span>
				<el-select
				  v-if="'supervise_status' == column.column_prop"
				  style="width: 200px"
				  @change="
					handleSearch(column.column_prop, searchParams.supervise_status)
				  "
				  v-model="searchParams.upload_status"
				  clearable
				  placeholder="请选择"
				  size="small"
				>
				  <el-option
					v-for="item in uploadStatus"
					:key="item.value"
					:label="item.label"
					:value="item.value"
				  >
				  </el-option>
				</el-select>
			  </el-popover>
			  <span v-else>{{
				column.column_label_user_definition
				  ? column.column_label_user_definition
				  : column.column_label
			  }}</span>
			</template>
		  </el-table-column>
		</template>
	  </el-table>
  
	  <!----------------分页---------------->
	  <!--<pagination :total="total" :page.sync="listQuery.page" -->
	  <!--						:limit.sync="listQuery.limit"-->
	  <!--						@pagination="getList"/>-->
	  <el-dialog
		:title="dialogTitle"
		:visible.sync="dialogTableVisible"
		v-if="dialogTableVisible"
		width="40%"
	  >
		<el-form
		  ref="ruleForm"
		  :model="form"
		  label-width="110px"
		  :rules="rules"
		  class="demo-ruleForm"
		>
		  <el-row>
			<el-col :span="12">
			  <el-form-item label="机构编码" prop="org_code">
				<el-input v-model="form.org_code"></el-input>
			  </el-form-item>
			</el-col>
			<el-col :span="12">
			  <el-form-item label="机构名称" prop="org_name">
				<el-input v-model="form.org_name"></el-input>
			  </el-form-item>
			</el-col>
		  </el-row>
		  <el-row>
			<el-col :span="12">
			  <el-form-item label="级别" prop="org_level">
				<el-input v-model="form.org_level"></el-input>
				<!-- <el-select
					v-model="form.org_level"
					placeholder="请选择"
					style="width: 100%"
				  >
					<el-option
					  v-for="item in typeOptions"
					  :key="item.label"
					  :label="item.label"
					  :value="item.label"
					>
					</el-option>
				  </el-select> -->
			  </el-form-item>
			</el-col>
			<el-col :span="12">
			  <el-form-item label="性质" prop="nature">
				<el-input v-model="form.nature"></el-input>
				<!-- <el-select
					v-model="form.nature"
					placeholder="请选择"
					style="width: 100%"
				  >
					<el-option
					  v-for="item in natureOptions"
					  :key="item.label"
					  :label="item.label"
					  :value="item.label"
					>
					</el-option>
				  </el-select> -->
			  </el-form-item>
			</el-col>
		  </el-row>
  
		  <el-row>
			<el-col :span="12">
			  <el-form-item label="联系电话" prop="org_tel">
				<el-input v-model="form.org_tel"></el-input>
			  </el-form-item>
			</el-col>
			<el-col :span="12">
			  <el-form-item label="负责人" prop="org_principa_name">
				<el-input v-model="form.org_principa_name"></el-input>
			  </el-form-item>
			</el-col>
		  </el-row>
  
		  <el-row>
			<el-col :span="12">
			  <el-form-item label="负责人电话" prop="org_principa_tel">
				<el-input v-model="form.org_principa_tel"></el-input>
			  </el-form-item>
			</el-col>
			<el-col :span="12">
			  <el-form-item label="签约年限" prop="sign_life">
				<el-input v-model="form.sign_life"></el-input>
			  </el-form-item>
			</el-col>
		  </el-row>
  
		  <el-row>
			<el-col :span="24">
			  <el-form-item label="签约时间" prop="sign_time">
				<el-date-picker
				  v-model="form.sign_time"
				  type="datetime"
				  value-format="yyyy-MM-dd HH:mm"
				  format="yyyy-MM-dd HH:mm"
				  placeholder="选择日期时间"
				>
				</el-date-picker>
			  </el-form-item>
			</el-col>
		  </el-row>
  
		  <el-row>
			<el-col :span="24">
			  <el-form-item label="机构地址" prop="address">
				<el-input v-model="form.address"></el-input>
			  </el-form-item>
			</el-col>
		  </el-row>
  
		  <el-row>
			<el-col :span="24">
			  <el-form-item label="机构简介" prop="org_comment">
				<el-input
				  type="textarea"
				  :row="4"
				  v-model="form.org_comment"
				></el-input>
			  </el-form-item>
			</el-col>
		  </el-row>
		</el-form>
  
		<span slot="footer" class="dialog-footer">
		  <el-button type="success" size="small" @click="saveForm('ruleForm')"
			>保存</el-button
		  >
		</span>
	  </el-dialog>
	  <pagination
		:total="total"
		:page.sync="listQuery.page"
		:limit.sync="listQuery.limit"
		@pagination="getList"
	  />
	  <!--编辑表头-->
	  <editTableHead
		:isActiveEditTableHead.sync="isActiveEditTableHead"
		@_getAdminFieldIndex="_getAdminFieldIndex"
		v-if="isActiveEditTableHead"
		@getList="getList"
    :table_type="table_type"
	  >
	  </editTableHead>
	</div>
  </template>
  
  <script>
  import editTableHead from "@/components/editTableHead/editTableHead";
  import { getAdminFieldIndex } from "@/api/drugs2.0";
  import Pagination from "@/components/Pagination"; // secondary package based on el-pagination
  import {
	getOrganList,
	addOrgan,
	updateOrgan,
	delOrgan,
  } from "@/api/audit/doctor";
  import { institutionUpload } from "@/api/audit/doctor2.0.js";
  import { mapState } from "vuex";
  
  export default {
	name: "organSupervise",
	async created() {
	  await this._getAdminFieldIndex();
	  this.getList();
	},
	mounted() {
    this.$store.dispatch("user/getOperates", this.$route.path).then((res) => {
      this.operateList = res;
    });
  },
	data() {
	  return {
      table_type:'organ',
		operateList: [],
		isActiveEditTableHead: false,
		multipleSelectionPres: [],
		multipleSelection: [],
		activities: [],
		tableHead: [],
		searchTableHead: [
		  //控制搜索
		  {
			name: "supervise_status",
			isSearch: false,
		  },
		],
		tableHeadSortable: [
		  //控制排序
		  "created_at",
		  "expired_at",
		  "next_visit_at",
		  "end_visit_at",
		  "end_order_at",
		],
		examineOptions: [
		  {
			label: "待审核",
			value: 1,
		  },
		  {
			label: "已审核",
			value: 2,
		  },
		  {
			label: "已拒绝",
			value: 3,
		  },
		],
		superviseOptions: [
		  {
			label: "未上传",
			value: 1,
		  },
		  {
			label: "待上传",
			value: 2,
		  },
		  {
			label: "已上传",
			value: 3,
		  },
		],
		dialogTitle: "",
		dialogType: "",
		datetimerange: [],
		currUser: {},
		dialogTableVisible: false,
		searchParams: {
		  examine_status: 2,
		},
		total: 0,
		listLoading: false,
		fullscreenLoading: false,
		listQuery: {
		  page: 1,
		  limit: 100,
		  importance: undefined,
		  title: undefined,
		  type: undefined,
		  sort: "+id",
		},
		tableData: [],
		options: [],
		ruleForm: {
		  examine: "",
		},
		typeOptions: [
		  {
			label: "未定级",
			value: 1,
		  },
		],
		natureOptions: [
		  {
			label: "民营",
			value: 1,
		  },
		],
		form: {},
		rules: {
		  org_name: [
			{
			  required: true,
			  message: "请填写机构名称",
			  trigger: "blur",
			},
		  ],
		  org_code: [
			{
			  required: true,
			  message: "请填写机构编码",
			  trigger: "blur",
			},
		  ],
		  org_level: [
			{
			  required: true,
			  message: "请选择级别",
			  trigger: "change",
			},
		  ],
		  nature: [
			{
			  required: true,
			  message: "请选择性质",
			  trigger: "change",
			},
		  ],
		  org_tel: [
			{
			  required: true,
			  message: "请填写联系电话",
			  trigger: "blur",
			},
		  ],
		  org_principa_name: [
			{
			  required: true,
			  message: "请填写负责人",
			  trigger: "blur",
			},
		  ],
		  org_principa_tel: [
			{
			  required: true,
			  message: "请填写负责人电话",
			  trigger: "blur",
			},
		  ],
		  sign_life: [
			{
			  required: true,
			  message: "请填写签约年限",
			  trigger: "blur",
			},
		  ],
		  sign_time: [
			{
			  required: true,
			  message: "请填写签约时间",
			  trigger: "change",
			},
		  ],
		  address: [
			{
			  required: true,
			  message: "请填写机构地址",
			  trigger: "blur",
			},
		  ],
		  org_comment: [
			{
			  required: true,
			  message: "请填写机构简介",
			  trigger: "blur",
			},
		  ],
		},
	  };
	},
	computed: {
	  getExamineName() {
		return (status) => {
		  let curExamine = this.examineOptions.find((v) => v.value == status);
		  return curExamine ? curExamine.label : "";
		};
	  },
	  getSuperviseName() {
		return (status) => {
		  let curSupervise = this.superviseOptions.find((v) => v.value == status);
		  return curSupervise ? curSupervise.label : "";
		};
	  },
	  ...mapState({
		uploadStatus: (state) => state.dictionary.uploadStatus,
		table_options: (state) => state.user.table_options,
	  }),
	},
	components: {
	  editTableHead,
	  Pagination,
	},
	methods: {
	  handleSelectionChange(val) {
		console.log(val, "val");
		this.multipleSelection = [];
		this.multipleSelectionPres = [];
		val.forEach((item) => {
		  this.multipleSelection.push(item.id);
		  this.multipleSelectionPres.push(item);
		});
	  },
	  handleSearch(prop, value) {
		console.log(prop, value, "2312343");
		this.searchTableHead.forEach((item) => {
		  if (item.name == prop) {
			if (value == "") {
			  item.isSearch = false;
			} else {
			  item.isSearch = true;
			}
		  }
		});
		this.getList("restPage");
	  },
	  handleResetSearch() {
		this.searchParams = {
		  is_wx: "",
		  visit_level: "",
		  buy_count: "",
		};
		this.searchTableHead.forEach((item) => {
		  item.isSearch = false;
		});
		this.choiceDateCreateRecord = [];
		this.choiceDateExpire = [];
		this.choiceDateEndVisit = [];
		this.choiceDateEndOrder = [];
		this.choiceDateNextVisit = [];
		this.getList("restPage");
	  },
	  handleEditTableHead() {
		this.isActiveEditTableHead = true;
	  },
	  async _getAdminFieldIndex() {
		// try {
		//   this.listLoading = true;
		//   var params = {
		// 	type: "organ",
		//   };
		//   const res = await getAdminFieldIndex(params);
		  this.tableHead = this.table_options.organ;
		//   console.log(this.tableHead);
		// } catch (err) {
		//   //在此处理错误
		// } finally {
		//   this.listLoading = false;
		// }
	  },
	  //上传监管
	  institutionUpload(id) {
		this.$confirm("是否上传监管", "提示", {
		  confirmButtonText: "确定",
		  cancelButtonText: "取消",
		  type: "warning",
		})
		  .then(() => {
			this.fullscreenLoading = true;
  
			console.log(id, 888);
			institutionUpload(id)
			  .then((res) => {
				if (res.code == 200) {
				  console.log("res:", res);
				  this.$message({
					type: "success",
					message: "上传成功",
				  });
				  this.getList();
				} else {
				  this.$message({
					type: "error",
					message: res.message ? res.message : res.msg,
				  });
				  this.getList();
				  this.fullscreenLoading = false;
				}
			  })
			  .catch((err) => {
				this.$message({
				  type: "error",
				  message: err.message ? err.message : err.msg,
				});
				this.fullscreenLoading = false;
			  });
		  })
		  .catch(() => {
			this.$message({
			  type: "info",
			  message: "已取消上传",
			});
		  });
	  },
	  saveForm(type) {
		this.$refs["ruleForm"].validate((valid) => {
		  if (valid) {
			if (this.dialogType == "add") {
			  this.addForm();
			} else {
			  this.updateForm();
			}
		  }
		});
	  },
	  delItem(row) {
		this.$confirm("确认删除该机构?", "提示", {
		  confirmButtonText: "确定",
		  cancelButtonText: "取消",
		  type: "warning",
		})
		  .then(() => {
			delOrgan(row.sign_id).then((res) => {
			  if (res.code == 200) {
				this.getList();
				this.$message({
				  type: "success",
				  message: "删除成功!",
				});
			  }
			});
		  })
		  .catch(() => {
			this.$message({
			  type: "info",
			  message: "已取消删除",
			});
		  });
	  },
	  addForm() {
		addOrgan(this.form)
		  .then((res) => {
			if (res.code == 200) {
			  this.getList();
			  this.$message.success("添加成功！");
			  this.dialogTableVisible = false;
			} else {
			  return this.$message.error(res.msg);
			}
		  })
		  .catch((err) => {
			console.log(err);
		  });
	  },
	  updateForm() {
		updateOrgan(this.form).then((res) => {
		  if (res.code == 200) {
			this.getList();
			this.$message.success("修改成功！");
			this.dialogTableVisible = false;
		  } else {
			return this.$message.error(res.msg);
		  }
		});
	  },
	  getList() {
		this.listLoading = true;
		this.fullscreenLoading = true;
		let params = {
		  ...this.searchParams,
		};
		params.page = this.listQuery.page;
		params.type = 1;
		params.page_size = this.listQuery.limit;
  
		getOrganList(params)
		  .then((response) => {
			let data = response;
			if (data.code == 200) {
			  let _data = data.data;
			  this.total = _data.total;
			  this.listQuery.limit = Number(_data.per_page);
			  this.tableData = _data.list;
			}
			this.fullscreenLoading = false;
			this.listLoading = false;
		  })
		  .catch((error) => {
			this.$message.error(error.message);
			this.listLoading = false;
			this.fullscreenLoading = false;
		  });
	  },
	  reset() {
		// this.searchParams = {};
		this.listQuery.page = 1;
		this.getList();
	  },
  
	  dateChange(val) {
		if (val && val.length) {
		  this.searchParams.created_at = JSON.stringify(val);
		} else {
		  this.searchParams.created_at = [];
		}
		this.getList();
	  },
	  // editOrgan(type, row) {
  
	  // 	if (type == "add") {
	  // 		this.form = {};
	  // 		this.dialogTitle = "新增机构";
	  // 	} else {
	  // 		this.dialogTitle = "修改机构";
	  // 		this.form = row;
	  // 	}
  
	  // 	this.dialogType = type;
	  // 	this.dialogTableVisible = true;
	  // },
	  editSuccess() {
		alert("编辑成功");
		this.dialogTableVisible = false;
		this.getList();
	  },
	},
	filters: {
	  controllshow(str, num) {
		//如果当前字符串小于nunm，返回原值
		if (str) {
		  if (str.length <= num) {
			return str;
		  }
		  if (str.length > num) {
			return str.substr(0, num - 1) + "...";
		  }
		} else {
		  return str;
		}
	  },
	},
  };
  </script>
  
  <style scoped lang="scss">
  .header {
	display: flex;
	align-items: center;
	height: 26px;
	margin-bottom: 10px;
 
	i {
	  width: 25px;
	  height: 25px;
	  background-color: #2632fb;
	  color: white;
	  line-height: 25px;
	  text-align: center;
	  border-radius: 4px;
	  margin-right: 8px;
	}
  }
  </style>
